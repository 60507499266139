<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-header>
        Detail End User <strong>( {{ this.users.name }} )</strong>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion1 variant="info" class="text-left">Detail User</b-btn>
                </b-card-header>
                <b-collapse id="accordion1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="mb-3">
                      <b-col sm="2">
                        <h6>User Full Name</h6>
                        <div class="mb-3" v-if="this.users.name == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.name }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>User Email Account</h6>
                        <div class="mb-3" v-if="this.users.email == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.email }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>Phone</h6>
                        <div class="mb-3" v-if="this.users.phone == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.phone }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>Address</h6>
                        <div class="mb-3" v-if="this.users.address == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.address }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>Referral Code</h6>
                        <div class="mb-3" v-if="this.users.referral_code == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.referral_code }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>Organization</h6>
                        <div class="mb-3" v-if="this.organization == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.organization }}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row class="justify-center-content mb-3">
                      <b-col sm="2">
                        <h6 >User Gold Balance</h6>
                        <div class="mb-3" v-if="this.users.gold_balance == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.gold_balance }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Gold Bonus</h6>
                        <div class="mb-3" v-if="this.users.bonus_gold_balance == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.bonus_gold_balance }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Voucher Balance</h6>
                        <div class="mb-3" v-if="this.balance.bucket_balance_gram == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.balance.bucket_balance_gram }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Gold Balance IDR</h6>
                        <div class="mb-3" v-if="this.balance.gold_balance == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.balance.gold_balance  | currency}}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Gold Bonus IDR</h6>
                        <div class="mb-3" v-if="this.balance.gold_bonus == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.balance.gold_bonus | currency}}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Voucher Balance IDR</h6>
                        <div class="mb-3" v-if="this.balance.bucket_balance == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.balance.bucket_balance }}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row class="justify-center-content mb-3">
                      <b-col sm="2">
                        <h6 >User Celengan Balance</h6>
                        <div class="mb-3"><strong>{{ this.users.wallet_balance | currency }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Bitcoin Balance</h6>
                        <div class="mb-3"><strong>{{ this.crypto.bitcoin_balance }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Ethereum Balance</h6>
                        <div class="mb-3"><strong>{{ this.crypto.ethereum_balance }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User USD Theter Balance</h6>
                        <div class="mb-3"><strong>{{ this.crypto.usdt_balance }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Binance Coin Balance</h6>
                        <div class="mb-3"><strong>{{ this.crypto.binance_balance }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6 >User Tokokripto Coin Balance</h6>
                        <div class="mb-3"><strong>{{ this.crypto.tokokripto_balance }}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="2">
                        <h6>Registration Date</h6>
                        <div class="mb-3" v-if="this.users.created_at == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.created_at }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>Status KYC KTP</h6>
                        <div class="mb-3" v-if="this.userCard != null && this.userCard.ktp_status == 0"><strong>Not Verified</strong></div>
                        <div class="mb-3" v-else-if="this.userCard != null && this.userCard.ktp_status == 1"><strong>Verified</strong></div>
                        <div class="mb-3" v-else-if="this.userCard != null && this.userCard.ktp_status == 2"><strong>Rejected</strong></div>
                        <div class="mb-3" v-else-if="this.userCard != null && this.userCard.ktp_status == 3"><strong>On Process</strong></div>
                        <div class="mb-3" v-else-if="this.userCard != null && this.userCard.ktp_status == 4"><strong>Submitted</strong></div>
                        <div class="mb-3" v-if="this.userCard == null"><strong>Data Tidak Ditemukan.</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>Status KYC Selfie</h6>
                        <div class="mb-3" v-if="this.userCard != null && this.userCard.selfie_status == 0"><strong>Not Verified</strong></div>
                        <div class="mb-3" v-else-if="this.userCard != null && this.userCard.selfie_status == 1"><strong>Verified</strong></div>
                        <div class="mb-3" v-else-if="this.userCard != null && this.userCard.selfie_status == 2"><strong>Rejected</strong></div>
                        <div class="mb-3" v-else-if="this.userCard != null && this.userCard.selfie_status == 3"><strong>On Process</strong></div>
                        <div class="mb-3" v-else-if="this.userCard != null && this.userCard.selfie_status == 4"><strong>Submitted</strong></div>
                        <div class="mb-3" v-if="this.userCard == null"><strong>Data Tidak Ditemukan.</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>NPWP</h6>
                        <div class="mb-3" v-if="this.users.tax_id_number == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.users.tax_id_number }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>Total Transaction</h6>
                        <div class="mb-3" v-if="this.totalTransaction == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.totalTransaction }}</strong></div>
                      </b-col>
                      <b-col sm="2">
                        <h6>Partner</h6>
                        <div class="mb-3" v-if="this.partner == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.partner }}</strong></div>
                      </b-col>
                      <b-col sm="2"></b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <h6>Provinsi</h6>
                        <div class="mb-3"><strong>{{ this.users.province_name === null ? '-' : this.users.province_name }}</strong></div>
                      </b-col>
                      <b-col>
                        <h6>Kota</h6>
                        <div class="mb-3"><strong>{{ this.users.city_name === null ? '-' : this.users.city_name }}</strong></div>
                      </b-col>
                      <b-col>
                        <h6>Kode Pos</h6>
                        <div class="mb-3"><strong>{{ this.users.postal_code === null ? '-' : this.users.postal_code }}</strong></div>
                      </b-col>
                      <b-col>
                        <h6>Last Account Update</h6>
                        <div class="mb-3"><strong>{{ this.users.updated_at === null ? '-' : this.users.updated_at }}</strong></div>
                      </b-col>
                      <b-col></b-col>
                      <b-col></b-col>
                    </b-row>
                    <b-row v-if="this.userCard != null">
                      <b-col v-if="this.userCard.ktp_status == 2">
                        <h6 v-if="this.userCard.ktp_status == 2">KTP Reason</h6>
                        <div class="mb-3" v-if="this.userCard.ktp_status == 2"><strong>{{ this.userCard.ktp_reason }}</strong></div>
                      </b-col>
                      <b-col v-if="this.userCard.selfie_status == 2">
                        <h6 v-if="this.userCard.selfie_status == 2">Selfie Reason</h6>
                        <div class="mb-3" v-if="this.userCard.selfie_status == 2"><strong>{{ this.userCard.selfie_reason }}</strong></div>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col>
                        <h6>KTP Reason</h6>
                        <div class="mb-3"><strong>Data Tidak Ditemukan.</strong></div>
                      </b-col>
                      <b-col>
                        <h6>Selfie Reason</h6>
                        <div class="mb-3"><strong>Data Tidak Ditemukan.</strong></div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion2 variant="info" class="text-left">History Beli Emas</b-btn>
                </b-card-header>
                <b-collapse id="accordion2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="6">
                        <h6 class="text-center">Total Buy Transaction</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.totalBuy == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.totalBuy }}</strong></div>
                      </b-col>
                      <b-col sm="6">
                        <h6 class="text-center">Total Gold Buy</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.buySumGold == null && this.detailSummary.buyPrice == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.buySumGold }} - {{ this.detailSummary.buyPrice | currency }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableBuy"
                        @vuetable:load-error="errorGoldBuy"
                        :api-url="goldBuyHistory"
                        :http-options="HttpOptions"
                        :fields="fieldsBuy"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderBuy"
                        :append-params="moreParamsBuy"
                        @vuetable:pagination-data="onPaginationDataBuy">
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="transaction_type-slot" slot-scope="prop">
                            <span>{{ prop.rowData.transaction_type }}</span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="idr_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.idr_amount}} </span>
                          </template>
                          <template slot="buying_rate-slot" slot-scope="prop">
                            <span>{{ prop.rowData.buying_rate}} </span>
                          </template>
                          <template slot="payment_method-slot" slot-scope="prop">
                            <span>{{ prop.rowData.payment_method}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.status}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoBuy"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationBuy"
                          @vuetable-pagination:change-page="onChangePageBuy"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion3 variant="info" class="text-left">History Jual Emas</b-btn>
                </b-card-header>
                <b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="6">
                        <h6 class="text-center">Total Sell Transaction</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.totalSell == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.totalSell }}</strong></div>
                      </b-col>
                      <b-col sm="6">
                        <h6 class="text-center">Total Gold Sell</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.sellSumGold == null && this.detailSummary.sellPrice == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.sellSumGold }} - {{ this.detailSummary.sellPrice | currency }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableSell"
                        @vuetable:load-error="errorGoldSell"
                        :api-url="goldSellHistory"
                        :http-options="HttpOptions"
                        :fields="fieldsSell"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderSell"
                        :append-params="moreParamsSell"
                        @vuetable:pagination-data="onPaginationDataSell">
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="idr_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.idr_amount}} </span>
                          </template>
                          <template slot="selling_rate-slot" slot-scope="prop">
                            <span>{{ prop.rowData.selling_rate}} </span>
                          </template>
                          <template slot="account_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.account_number}} </span>
                          </template>
                          <template slot="owner_name-slot" slot-scope="prop">
                            <span>{{ prop.rowData.owner_name}} </span>
                          </template>
                          <template slot="bank_name-slot" slot-scope="prop">
                            <span>{{ prop.rowData.bank_name}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.status}} </span>
                          </template>
                          <template slot="reason-slot" slot-scope="prop">
                            <span>{{ prop.rowData.reason}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoSell"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationSell"
                          @vuetable-pagination:change-page="onChangePageSell"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion4 variant="info" class="text-left">History Redeem Voucher</b-btn>
                </b-card-header>
                <b-collapse id="accordion4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col>
                        <h6 class="text-center">Total Redeem Voucher Transaction</h6>
                        <div class="mb-3 text-center"><strong>{{ this.bucket.total_redeem }}</strong></div>
                      </b-col> -->
                      <!-- <b-col sm="6">
                        <h6 class="text-center">Total Redeem Voucher</h6>
                        <div class="mb-3 text-center" v-if="this.bucket.gold_amount == null && this.balance.amount == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.bucket.gold_amount }} - {{ this.bucket.amount | currency }}</strong></div>
                      </b-col> -->
                    <!-- </b-row>
                    <div>
                      <vuetable ref="vuetableVoucher"
                        @vuetable:load-error="errorVoucherRedeem"
                        :api-url="historyVoucher"
                        :http-options="HttpOptions"
                        :fields="fieldsVoucher"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderVoucher"
                        :append-params="moreParamsVoucher"
                        @vuetable:pagination-data="onPaginationDataVoucher">
                          <template slot="voucher_code-slot" slot-scope="prop">
                            <span>{{ prop.rowData.vouchers.code == null ? '-' : prop.rowData.vouchers.code }}</span>
                          </template>
                          <template slot="voucher_name-slot" slot-scope="prop">
                            <span>{{ prop.rowData.vouchers.name}} </span>
                          </template>
                          <template slot="gold_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.gold_amount === null ? '0' : prop.rowData.gold_amount }} </span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="buying_rate-slot" slot-scope="prop">
                            <span>{{ prop.rowData.buying_rate === null ? '0' : prop.rowData.buying_rate}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoVoucher"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationVoucher"
                          @vuetable-pagination:change-page="onChangePageVoucher"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion5 variant="info" class="text-left">History Cetak Emas</b-btn>
                </b-card-header>
                <b-collapse id="accordion5" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="4">
                        <h6 class="text-center">Total Gold Mint Transaction</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.totalRedeem == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.totalRedeem }}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6 class="text-center">Total Cetak Emas</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.redeemSumGold == null && this.detailSummary.redeemPriceGold == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.redeemSumGold }} - {{ this.detailSummary.redeemPriceGold | currency }}</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6 class="text-center">Total Cetak Koin</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.redeemSumCoin == null && this.detailSummary.redeemPriceCoin == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.redeemSumCoin }} - {{ this.detailSummary.redeemPriceCoin | currency }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableRedeem"
                        @vuetable:load-error="errorGoldRedeem"
                        :api-url="goldRedeemHistory"
                        :http-options="HttpOptions"
                        :fields="fieldsRedeem"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderRedeem"
                        :append-params="moreParamsRedeem"
                        @vuetable:pagination-data="onPaginationDataRedeem">
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="transaction_type-slot" slot-scope="prop">
                            <span>{{ prop.rowData.transaction_type}} </span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="idr_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.idr_amount}} </span>
                          </template>
                          <template slot="payment_method-slot" slot-scope="prop">
                            <span>{{ prop.rowData.payment_method}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.status}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoRedeem"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationRedeem"
                          @vuetable-pagination:change-page="onChangePageRedeem"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion6 variant="info" class="text-left">History Donasi</b-btn>
                </b-card-header>
                <b-collapse id="accordion6" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="6">
                        <h6 class="text-center">Total Donation Transaction</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.totalDonation == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.totalDonation }}</strong></div>
                      </b-col>
                      <b-col sm="6">
                        <h6 class="text-center">Total Donation</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.donationSumGold == null || this.detailSummary.donationPrice == null" ><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.donationSumGold }} - {{ this.detailSummary.donationPrice | currency }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableDonation"
                        @vuetable:load-error="errorGoldDonation"
                        :api-url="goldDonationHistory"
                        :http-options="HttpOptions"
                        :fields="fieldsDonation"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderDonation"
                        :append-params="moreParamsDonation"
                        @vuetable:pagination-data="onPaginationDataDonation">
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="organization_name-slot" slot-scope="prop">
                            <span>{{ prop.rowData.organization_name}} </span>
                          </template>
                          <template slot="organization_email-slot" slot-scope="prop">
                            <span>{{ prop.rowData.organization_email}} </span>
                          </template>
                          <template slot="transaction_type-slot" slot-scope="prop">
                            <span>{{ prop.rowData.transaction_type}} </span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="idr_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.idr_amount}} </span>
                          </template>
                          <template slot="payment_method-slot" slot-scope="prop">
                            <span>{{ prop.rowData.payment_method}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.status}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoDonation"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationDonation"
                          @vuetable-pagination:change-page="onChangePageDonation"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion7 variant="info" class="text-left">History Zakat</b-btn>
                </b-card-header>
                <b-collapse id="accordion7" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="6">
                        <h6 class="text-center">Total Zakat Transaction</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.totalZakat == null"><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.totalZakat }}</strong></div>
                      </b-col>
                      <b-col sm="6">
                        <h6 class="text-center">Total Zakat Payment</h6>
                        <div class="mb-3 text-center" v-if="this.detailSummary.zakatSumGold == null || this.detailSummary.zakatPrice == null" ><strong>-</strong></div>
                        <div class="mb-3 text-center" v-else><strong>{{ this.detailSummary.zakatSumGold }} - {{ this.detailSummary.zakatPrice | currency }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableZakat"
                        @vuetable:load-error="errorGoldZakat"
                        :api-url="goldZakatHistory"
                        :http-options="HttpOptions"
                        :fields="fieldsZakat"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderZakat"
                        :append-params="moreParamsZakat"
                        @vuetable:pagination-data="onPaginationDataZakat">
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="name-slot" slot-scope="prop">
                            <span>{{ prop.rowData.name}} </span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="idr_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.idr_amount}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.status}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoZakat"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationZakat"
                          @vuetable-pagination:change-page="onChangePageZakat"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <b-card no-body class="mb-1" v-if="$can('update_verify_kyc')">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion8 variant="info" class="text-left">User Access Right</b-btn>
                </b-card-header>
                <b-collapse id="accordion8" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div v-if="$can('update_verify_kyc')">
                      <b-form v-on:submit.prevent="onSubmit">
                        <div v-for="(item, index) in accessRight" :key="index">
                          <b-row class="mb-4">
                            <b-col sm="2">
                              <b-form-group>
                                <b-form-checkbox :value="true" v-model="item.status">{{ item.name }}</b-form-checkbox>
                              </b-form-group>
                            </b-col>
                            <b-col sm="10">
                              <b-form-group>
                                <b-form-input type="text"
                                  v-model="item.reason"
                                  placeholder="Reason (Dibutuhkan untuk pop up jika fitur dinonaktifkan)">
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                        <b-button type="submit" class="submt btn btn-success">Submit</b-button>
                      </b-form>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion9 variant="info" class="text-left">History Beli Kripto</b-btn>
                </b-card-header>
                <b-collapse id="accordion9" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="6">
                        <h6 class="text-center">Total Buy Transaction</h6>
                        <div class="mb-3 text-center"><strong>{{ this.cryptoSummary.buy_asset.total }}</strong></div>
                      </b-col>
                      <b-col sm="6">
                        <h6 class="text-center">Total Kripto Buy</h6>
                        <div class="mb-3 text-center"><strong>{{ this.cryptoSummary.buy_asset.amount }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableCryptoBuy"
                        @vuetable:load-error="errorCryptoBuy"
                        :api-url="cryptoBuy"
                        :http-options="HttpOptions"
                        :fields="fieldsCryptoBuy"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderCryptoBuy"
                        :append-params="moreParamsCryptoBuy"
                        @vuetable:pagination-data="onPaginationDataCryptoBuy">
                          <template slot="created_at-slot" slot-scope="prop">
                            <span>{{ prop.rowData.created_at }}</span>
                          </template>
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="source_crypto-slot" slot-scope="prop">
                            <span>{{ prop.rowData.source_crypto }}</span>
                          </template>
                          <template slot="asset_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.asset_amount}} </span>
                          </template>
                          <template slot="trs_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.trs_amount}} </span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.transaction_status}} </span>
                          </template>
                          <template slot="buying_rate-slot" slot-scope="prop">
                            <span>{{ prop.rowData.buying_rate}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoCryptoBuy"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationCryptoBuy"
                          @vuetable-pagination:change-page="onChangePageCryptoBuy"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion10 variant="info" class="text-left">History Jual Kripto</b-btn>
                </b-card-header>
                <b-collapse id="accordion10" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="6">
                        <h6 class="text-center">Total Sell Transaction</h6>
                        <div class="mb-3 text-center"><strong>{{ this.cryptoSummary.sell_asset.total }}</strong></div>
                      </b-col>
                      <b-col sm="6">
                        <h6 class="text-center">Total Kripto Sell</h6>
                        <div class="mb-3 text-center"><strong>{{ this.cryptoSummary.sell_asset.amount }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableCryptoSell"
                        @vuetable:load-error="errorCryptoSell"
                        :api-url="cryptoSell"
                        :http-options="HttpOptions"
                        :fields="fieldsCryptoSell"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderCryptoSell"
                        :append-params="moreParamsCryptoSell"
                        @vuetable:pagination-data="onPaginationDataCryptoSell">
                          <template slot="created_at-slot" slot-scope="prop">
                            <span>{{ prop.rowData.created_at }}</span>
                          </template>
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="source_crypto-slot" slot-scope="prop">
                            <span>{{ prop.rowData.source_crypto }}</span>
                          </template>
                          <template slot="asset_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.asset_amount}} </span>
                          </template>
                          <template slot="trs_amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.trs_amount}} </span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.transaction_status}} </span>
                          </template>
                          <template slot="selling_rate-slot" slot-scope="prop">
                            <span>{{ prop.rowData.selling_rate}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoCryptoSell"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationCryptoSell"
                          @vuetable-pagination:change-page="onChangePageCryptoSell"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion11 variant="info" class="text-left">History Top Up Celengan</b-btn>
                </b-card-header>
                <b-collapse id="accordion11" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="6">
                        <h6 class="text-center">Total Top Up Transaction</h6>
                        <div class="mb-3 text-center"><strong>{{ this.walletSummary.top_up.total }}</strong></div>
                      </b-col>
                      <b-col sm="6">
                        <h6 class="text-center">Total Amount Top Up</h6>
                        <div class="mb-3 text-center"><strong>{{ this.walletSummary.top_up.amount }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableTopUp"
                        @vuetable:load-error="errorTopUp"
                        :api-url="topup"
                        :http-options="HttpOptions"
                        :fields="fieldsTopUp"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderTopUp"
                        :append-params="moreParamsTopUp"
                        @vuetable:pagination-data="onPaginationDataTopUp">
                          <template slot="created_at-slot" slot-scope="prop">
                            <span>{{ prop.rowData.created_at }}</span>
                          </template>
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="payment_method-slot" slot-scope="prop">
                            <span>{{ prop.rowData.payment_method}} </span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.transaction_status}} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoTopUp"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationTopUp"
                          @vuetable-pagination:change-page="onChangePageTopUp"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
              <!-- <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-btn block href="#" v-b-toggle.accordion12 variant="info" class="text-left">History Withdraw Celengan</b-btn>
                </b-card-header>
                <b-collapse id="accordion12" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row class="justify-content-center">
                      <b-col sm="6">
                        <h6 class="text-center">Total Withdraw Transaction</h6>
                        <div class="mb-3 text-center"><strong>{{ this.walletSummary.withdraw.total }}</strong></div>
                      </b-col>
                      <b-col sm="6">
                        <h6 class="text-center">Total Amount Withdraw</h6>
                        <div class="mb-3 text-center"><strong>{{ this.walletSummary.withdraw.amount }}</strong></div>
                      </b-col>
                    </b-row>
                    <div>
                      <vuetable ref="vuetableWithdraw"
                        @vuetable:load-error="errorWithdraw"
                        :api-url="withdraw"
                        :http-options="HttpOptions"
                        :fields="fieldsWithdraw"
                        pagination-path=""
                        :muti-sort="true"
                        :sort-order="sortOrderWithdraw"
                        :append-params="moreParamsWithdraw"
                        @vuetable:pagination-data="onPaginationDataWithdraw">
                          <template slot="created_at-slot" slot-scope="prop">
                            <span>{{ prop.rowData.created_at }}</span>
                          </template>
                          <template slot="invoice_number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.invoice_number == null ? '-' : prop.rowData.invoice_number}}</span>
                          </template>
                          <template slot="bank_account-slot" slot-scope="prop">
                            <span>{{ prop.rowData.bank_account.owner_name }} - {{ prop.rowData.bank_account.bank_name }} - {{ prop.rowData.bank_account.account_number }} </span>
                          </template>
                          <template slot="amount-slot" slot-scope="prop">
                            <span>{{ prop.rowData.amount}} </span>
                          </template>
                          <template slot="status-slot" slot-scope="prop">
                            <span>{{ prop.rowData.transaction_status}} </span>
                          </template>
                          <template slot="reason-slot" slot-scope="prop">
                            <span>{{ prop.rowData.transaction_note }} </span>
                          </template>
                        </vuetable>
                      <div class="vuetable-pagination ui basic segment grid">
                        <vuetable-pagination-info ref="paginationInfoWithdraw"
                        ></vuetable-pagination-info>

                        <vuetable-pagination ref="paginationWithdraw"
                          @vuetable-pagination:change-page="onChangePageWithdraw"
                        ></vuetable-pagination>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card> -->
            </div>
          </b-col>
        </b-row>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
// import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
// import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
Vue.use(Vuetable);
export default {
  name: 'detail-user',
  components: {
    // Vuetable,
    // VuetablePagination,
    // VuetablePaginationInfo,
  },
  data() {
    return {
      statusChecked: false,
      status: '',
      partner: null,
      userCard: '',
      organization: null,
      totalTransaction: null,
      accessRight: '',
      asccessRights: [],
      reason: [],
      balance: '',
      bucket: [],
      name: '',
      users: '',
      detailSummary: '',
      user: '',
      feature: '',
      crypto: [],
      cryptoSummary: [],
      walletSummary: [],
      HttpOptions: {
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      goldBuyHistory: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-gold-buy/` + atob(this.$route.params.id),
      sortOrderBuy: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParamsBuy: {},
      fieldsBuy: [
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Transaction Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'transaction_type-slot',
          title: 'Transaction Type'
        },
        {
          name: 'amount-slot',
          title: 'Gold Amount',
        },
        {
          name: 'idr_amount-slot',
          title: 'IDR Amount',
        },
        {
          name: 'buying_rate-slot',
          title: 'Gold Buy Price'
        },
        {
          name: 'payment_method-slot',
          title: 'Payment Method'
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        }
      ],
      goldSellHistory: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-gold-sell/` + atob(this.$route.params.id),
      sortOrderSell: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParamsSell: {},
      fieldsSell: [
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Transaction Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'amount-slot',
          title: 'Gold Amount',
        },
        {
          name: 'idr_amount-slot',
          title: 'IDR Amount',
        },
        {
          name: 'selling_rate-slot',
          title: 'Gold Sell Price'
        },
        {
          name: 'account_number-slot',
          title: 'Bank Account Number'
        },
        {
          name: 'owner_name-slot',
          title: 'Bank Owner Name'
        },
        {
          name: 'bank_name-slot',
          title: 'Bank Name'
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        },
        {
          name: 'reason-slot',
          title: 'Transaction Note'
        }
      ],
      historyVoucher: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-voucher-redeem/` + atob(this.$route.params.id),
      sortOrderVoucher: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParamsVoucher: {},
      fieldsVoucher: [
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Transaction Date'
        },
        {
          name: 'voucher_code-slot',
          title: 'Voucher Code'
        },
        {
          name: 'voucher_name-slot',
          title: 'Voucher Name'
        },
        {
          name: 'gold_amount-slot',
          title: 'Gold Amount',
        },
        {
          name: 'amount-slot',
          title: 'IDR Amount',
        },
        {
          name: 'buying_rate-slot',
          title: 'Gold Buy Price'
        },
      ],
      goldRedeemHistory: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-gold-redeem/` + atob(this.$route.params.id),
      sortOrderRedeem: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParamsRedeem: {},
      fieldsRedeem: [
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Transaction Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'transaction_type-slot',
          title: 'Transaction Type'
        },
        {
          name: 'amount-slot',
          title: 'Gold Amount',
        },
        {
          name: 'idr_amount-slot',
          title: 'IDR Amount',
        },
        {
          name: 'payment_method-slot',
          title: 'Payment Method'
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        },
      ],
      goldDonationHistory: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-gold-donation/` + atob(this.$route.params.id),
      sortOrderDonation: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParamsDonation: {},
      fieldsDonation: [
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Transaction Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'organization_name-slot',
          title: 'Organization Name'
        },
        {
          name: 'organization_email-slot',
          title: 'Organization Email'
        },
        {
          name: 'transaction_type-slot',
          title: 'Transaction Type'
        },
        {
          name: 'amount-slot',
          title: 'Gold Amount',
        },
        {
          name: 'idr_amount-slot',
          title: 'IDR Amount',
        },
        {
          name: 'payment_method-slot',
          title: 'Payment Method'
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        },

      ],
      goldZakatHistory: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-gold-zakat/` + atob(this.$route.params.id),
      sortOrderZakat: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParamsZakat: {},
      fieldsZakat: [
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Transaction Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'name-slot',
          title: 'Zakat Name'
        },
        {
          name: 'amount-slot',
          title: 'Gold Amount'
        },
        {
          name: 'idr_amount-slot',
          title: 'IDR Amount'
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        },

      ],
      cryptoBuy: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-crypto-buy/`  + atob(this.$route.params.id),
      sortOrderCryptoBuy: [
        {
          field: 'crypto_transaction.created_at',
          sortField: 'crypto_transaction.created_at',
          direction: 'desc'
        }
      ],
      moreParamsCryptoBuy: {},
      fieldsCryptoBuy: [
        {
          name: 'created_at',
          title: 'Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'source_crypto-slot',
          title: 'Asset Crypto'
        },
        {
          name: 'trs_amount-slot',
          title: 'Asset Amount',
        },
        {
          name: 'asset_amount-slot',
          title: 'Asset TKO Amount',
        },
        {
          name: 'amount-slot',
          title: 'IDR Amount',
        },
        {
          name: 'buying_rate-slot',
          title: 'Asset Buy TRS Price',
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        }
      ],
      cryptoSell: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-crypto-sell/`  + atob(this.$route.params.id),
      sortOrderCryptoSell: [
        {
          field: 'crypto_transaction.created_at',
          sortField: 'crypto_transaction.created_at',
          direction: 'desc'
        }
      ],
      moreParamsCryptoSell: {},
      fieldsCryptoSell: [
        {
          name: 'created_at',
          title: 'Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'source_crypto-slot',
          title: 'Asset Crypto'
        },
        {
          name: 'trs_amount-slot',
          title: 'Asset Amount',
        },
        {
          name: 'asset_amount-slot',
          title: 'Asset TKO Amount',
        },
        {
          name: 'amount-slot',
          title: 'IDR Amount',
        },
        {
          name: 'selling_rate-slot',
          title: 'Asset Sell Price',
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        }
      ],
      topup: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-top-up/`  + atob(this.$route.params.id),
      sortOrderTopUp: [
        {
          field: 'created_at',
          sortField: 'created_at',
          direction: 'desc'
        }
      ],
      moreParamsTopUp: {},
      fieldsTopUp: [
        {
          name: 'created_at',
          title: 'Transaction Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'amount-slot',
          title: 'IDR Amount'
        },
        {
          name: 'payment_method-slot',
          title: 'Payment Method',
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        }
      ],
      withdraw: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-withdraw/`  + atob(this.$route.params.id),
      sortOrderWithdraw: [
        {
          field: 'created_at',
          sortField: 'created_at',
          direction: 'desc'
        }
      ],
      moreParamsWithdraw: {},
      fieldsWithdraw: [
        {
          name: 'created_at',
          title: 'Transaction Date'
        },
        {
          name: 'invoice_number-slot',
          title: 'Invoice'
        },
        {
          name: 'amount-slot',
          title: 'IDR Amount'
        },
        {
          name: 'bank_account-slot',
          title: 'Tujuan Pencairan Dana',
        },
        {
          name: 'status-slot',
          title: 'Transaction Status'
        },
        {
          name: 'reason-slot',
          title: 'Transaction Note'
        }
      ],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
      },
      isLoading: false
    }
  },
  async created () {
    await this.$http.get(`detail-user/` + atob(this.$route.params.id))
      .then((result) => {
          this.users = result.data.user;
          this.partner = result.data.partner;
          this.organization = result.data.organization;
          this.userCard = result.data.userCard;
          this.accessRight = result.data.accessRights;
          this.totalTransaction = result.data.totalTransaction;
          this.balance = result.data.balance;
          this.bucket = result.data.bucket
          this.crypto = result.data.crypto
      }).catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      })
    await this.$http.get(`detail-user-summary/` + atob(this.$route.params.id))
      .then((result) => {
        this.detailSummary = result.data.data
        this.cryptoSummary = result.data.data.crypto
        this.walletSummary = result.data.data.wallet
      }).catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      })
  },
  methods: {
    errorGoldBuy(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    errorGoldSell(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    errorGoldRedeem(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    errorVoucherRedeem(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    errorGoldDonation(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    errorGoldZakat(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    errorCryptoBuy(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    errorCryptoSell(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    onChangePageSell (page) {
      this.$refs.vuetableSell.changePage(page)
    },
    onPaginationDataSell (paginationData) {
      this.$refs.paginationSell.setPaginationData(paginationData)
      this.$refs.paginationInfoSell.setPaginationData(paginationData)
    },
    onChangePageZakat (page) {
      this.$refs.vuetableZakat.changePage(page)
    },
    onPaginationDataZakat (paginationData) {
      this.$refs.paginationZakat.setPaginationData(paginationData)
      this.$refs.paginationInfoZakat.setPaginationData(paginationData)
    },
    onChangePageDonation (page) {
      this.$refs.vuetableDonation.changePage(page)
    },
    onPaginationDataDonation (paginationData) {
      this.$refs.paginationDonation.setPaginationData(paginationData)
      this.$refs.paginationInfoDonation.setPaginationData(paginationData)
    },
    onChangePageRedeem (page) {
      this.$refs.vuetableRedeem.changePage(page)
    },
    onPaginationDataRedeem (paginationData) {
      this.$refs.paginationRedeem.setPaginationData(paginationData)
      this.$refs.paginationInfoRedeem.setPaginationData(paginationData)
    },onChangePageVoucher (page) {
      this.$refs.vuetableVoucher.changePage(page)
    },
    onPaginationDataVoucher (paginationData) {
      this.$refs.paginationVoucher.setPaginationData(paginationData)
      this.$refs.paginationInfoVoucher.setPaginationData(paginationData)
    },
    onChangePageBuy (page) {
      this.$refs.vuetableBuy.changePage(page)
    },
    onPaginationDataBuy (paginationData) {
      this.$refs.paginationBuy.setPaginationData(paginationData)
      this.$refs.paginationInfoBuy.setPaginationData(paginationData)
    },
    onChangePageCryptoBuy (page) {
      this.$refs.vuetableCryptoBuy.changePage(page)
    },
    onPaginationDataCryptoBuy (paginationData) {
      this.$refs.paginationCryptoBuy.setPaginationData(paginationData)
      this.$refs.paginationInfoCryptoBuy.setPaginationData(paginationData)
    },
    onChangePageCryptoSell (page) {
      this.$refs.vuetableCryptoSell.changePage(page)
    },
    onPaginationDataCryptoSell (paginationData) {
      this.$refs.paginationCryptoSell.setPaginationData(paginationData)
      this.$refs.paginationInfoCryptoSell.setPaginationData(paginationData)
    },
    errorTopUp(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    onChangePageTopUp (page) {
      this.$refs.vuetableTopUp.changePage(page)
    },
    onPaginationDataTopUp (paginationData) {
      this.$refs.paginationTopUp.setPaginationData(paginationData)
      this.$refs.paginationInfoTopUp.setPaginationData(paginationData)
    },
    errorWithdraw(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    onChangePageWithdraw (page) {
      this.$refs.vuetableWithdraw.changePage(page)
    },
    onPaginationDataWithdraw (paginationData) {
      this.$refs.paginationWithdraw.setPaginationData(paginationData)
      this.$refs.paginationInfoWithdraw.setPaginationData(paginationData)
    },
    onSubmit() {
      const access = this.accessRight.map((e) => {
        return {
          id: e.id,
          name: e.name,
          feature_id: e.feature_id,
          status: e.status,
          reason: e.reason
        }
      })
      this.isLoading = true
      this.$http.post(`updateAccessright`, {
        hashed: atob(this.$route.params.id),
        access_right: access
      }).then(() => {
        this.isLoading = false
        this.$swal.fire(
          'Success!',
          'Access was successfully saved.',
          'success'
        ).then(() =>{
          location.reload()
        })
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status;
          this.errors.status = error.response.data.meta.code;
          this.errors.headers = error.response.headers;
          this.errors.message = error.response.data.meta.message;
          if (this.errors.code == 422) {
            this.$swal.fire(
              'Failed!',
              this.errors.message,
              'error'
            ).then(() =>{
              location.reload()
            })
          }
        }
      })
    }
  },
}
</script>

<style>
  .submt {
      width: 20%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
